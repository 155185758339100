import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate,useLocation } from "react-router-dom";
import PasswordIcon from "@mui/icons-material/Password";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import HomeIcon from "@mui/icons-material/Home";
import { MdBookmarkAdded } from "react-icons/md";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import LogoutIcon from "@mui/icons-material/Logout";
import ContactsIcon from "@mui/icons-material/Contacts";
import logo from '../../../../Assets/Image/logo.png'
import "./Header.css";
import clients from "../../Client/Clients";

export default function Header(props) {
  const { setAdmin } = props;
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation(); 

  const logout = async() => {
    try {
      
      const response = await clients.post("/admins/logout",{}, {
        withCredentials:true
      });
  
      if (response.status===200) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        setAdmin(null); 
        navigate("/"); 
      } else {
        console.log("Failed to log out");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleItemClick = (path) => {
    navigate(path);
    setOpen(false);
  };

  const primaryLinks = [
    { text: "Home", path: "/", icon: <HomeIcon /> },
    { text: "Products", path: "/admin/products", icon: <ShoppingCartIcon /> },
    {
      text: "Contact Details",
      path: "/admin/contact-details",
      icon: <ContactsIcon />,
    },
    {
      text: "Manage Products",
      path: "/admin/manage-products",
      icon: <ManageSearchIcon />,
    },
    {
      text: "Manage Booking",
      path: "/admin/manage-booking",
      icon: <MdBookmarkAdded />,
    },
  ];

  const secondaryLinks = [
    {
      text: "Change Password",
      path: "/admin/update-password",
      icon: <PasswordIcon />,
    },
    
  ];

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
       <div style={{ textAlign: 'center', padding: '10px 0' }}>
        <img src={logo} alt="VinMedi Tech Logo" style={{ width: "60px", height: "60px" }} />
        <p style={{ fontSize: "18px", fontWeight: "bold", margin: 0 }}>VinMedi Tech</p>
      </div>
      <Divider />
    <List>
      {primaryLinks.map((link) => (
        <ListItem
          key={link.text}
          disablePadding
          sx={{
            backgroundColor: location.pathname === link.path ? "rgb(210, 235, 205)" : "transparent",
          }}
        >
          <ListItemButton onClick={() => handleItemClick(link.path)} style={{
            padding:10
          }}>
            <ListItemIcon>{link.icon}</ListItemIcon>
            <ListItemText primary={link.text} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
    <Divider />
    <List>
      {secondaryLinks.map((link) => (
        <ListItem
          key={link.text}
          disablePadding
          sx={{
            backgroundColor: location.pathname === link.path ? "rgb(210, 235, 205)" : "transparent",
          }} 
        >
          <ListItemButton onClick={() => handleItemClick(link.path)} style={{
            padding:10
          }}>
            <ListItemIcon>{link.icon}</ListItemIcon>
            <ListItemText primary={link.text} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
    <Divider />
    <List>
    
      <ListItem disablePadding>
        <ListItemButton onClick={logout}>
          <ListItemIcon>
            <LogoutIcon style={{ color: "red" }} />
          </ListItemIcon>
          <ListItemText
            primary="Logout"
            sx={{ color: "red" }}
          />
        </ListItemButton>
      </ListItem>
    </List>
  </Box>
  );

  return (
    <React.Fragment>
      <div
        className="header"
        style={{
          position: "fixed",
          top: 0,
          zIndex: 10,
          width: "100%",
          backgroundColor: "rgb(210, 235, 205)",
          color: "#ffffff",
        }}
      >
        <div>
          <Button onClick={toggleDrawer(true)}>
            <DensityMediumIcon />
          </Button>
          <Drawer open={open} onClose={toggleDrawer(false)}>
            {DrawerList}
          </Drawer>
        </div>
        <div className="headingLine">
          <p
            style={{
              color: "black",
              textAlign: "center",
            }}
          >
            Sri Vigneshwara MediTech
          </p>
        </div>
        <div className="logoutButton" onClick={logout}>
          <LogoutIcon
            style={{
              color: "red",
            }}
          />
          <span
            style={{
              color: "red",
              marginLeft: "5px",
              marginRight: "10px",
              cursor: "pointer",
            }}
          >
            Logout
          </span>
        </div>
      </div>
    </React.Fragment>
  );
}
