import React, { Fragment, useState, useEffect } from "react";
import { FormLabel, TextField, Button } from "@mui/material";
import "./Contact.css";
import clients from "../../Common/Client/Clients";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { BsWhatsapp } from "react-icons/bs";
import { FaPhone } from "react-icons/fa6";
import { Modal, Form } from "react-bootstrap";

const Contact = () => {
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [error, setError] = useState({
    address: "",
    phone: "",
    email: "",
    whatsapp: "",
  });
  const [id, setId] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getFetch();
  }, []);

  const getFetch = async () => {
    try {
      const response = await clients.get("/contact/get-contact");
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDatapass = (item) => {
    setOpen(true);

    setId(item._id);
    setAddress(item.address);
    setEmail(item.email);
    setPhone(item.phone);
    setWhatsapp(item.whatsapp);
  };
  const handleClose = () => {
    setOpen(false);
    setId("");
    setAddress("");
    setEmail("");
    setPhone("");
    setWhatsapp("");
    setError({ address: "", phone: "", email: "", whatsapp: "" });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setError((prevError) => ({
        ...prevError,
        [name]: `${name} is required`,
      }));
    }
  };

  const errorMessage = (fieldName, fieldValue) => {
    let message;
    if (fieldName) {
      if (fieldValue === "") {
        message = "";
      }
    }

    if (fieldName === "address") {
      if (fieldValue.length < 10) {
        message = `Address is Invalid`;
      } else {
        message = "";
      }
    }
    if (fieldName === "phone") {
      // Remove non-numeric characters for validation
      const numericValue = fieldValue.replace(/[^0-9]/g, "");

      if (numericValue.length < 10) {
        message = "Phone number needs 10 characters";
      } else if (numericValue.length > 10) {
        message = "Phone number is too long";
      } else {
        const prefix = parseInt(numericValue.slice(0, 2), 10);
        if (!(prefix >= 63 && prefix <= 99)) {
          message = "Invalid Phone Number";
        } else {
          message = "";
        }
      }
    }
    if (fieldName === "whatsapp") {
      // Remove non-numeric characters for validation
      const numericValue = fieldValue.replace(/[^0-9]/g, "");

      if (numericValue.length < 10) {
        message = "Whatsapp number needs 10 characters";
      } else if (numericValue.length > 10) {
        message = "Whatsapp number is too long";
      } else {
        const prefix = parseInt(numericValue.slice(0, 2), 10);
        if (!(prefix >= 63 && prefix <= 99)) {
          message = "Invalid Whatsapp Number";
        } else {
          message = "";
        }
      }
    }

    if (fieldName === "email") {
      const emailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{2,}@[a-zA-Z-]+\.[a-zA-Z-]{2,}$/;
      if (!emailRegex.test(fieldValue)) {
        message = `Email is Invalid`;
      } else {
        message = "";
      }
    }
    return { message: message };
  };

  const handleChange = (e) => {
    const value = e.target.value.trimLeft();
    const { name } = e.target;
    const err = errorMessage(name, value).message;

    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    if (name === "address") {
      setAddress(value);
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "whatsapp") {
      setWhatsapp(value);
    } else {
      setEmail(value);
    }
  };

  const handleSubmit = () => {
    if (address === "") {
      setError((pre) => ({
        ...pre,
        address: "Address is required",
      }));
    } else if (email === "") {
      setError((pre) => ({
        ...pre,
        email: "Email is required",
      }));
    } else if (whatsapp === "") {
      setError((pre) => ({
        ...pre,
        whatsapp: "Whatsapp Number is required",
      }));
    } else if (phone === "") {
      setError((pre) => ({
        ...pre,
        phone: "Phone Number is required",
      }));
    } else if (error.address !== "") {
      setError((pre) => ({
        ...pre,
        address: "Address is required",
      }));
      setAddress("");
    } else if (error.email !== "") {
      setError((pre) => ({
        ...pre,
        email: "Email is required",
      }));
      setEmail("");
    } else if (error.phone !== "") {
      setError((pre) => ({
        ...pre,
        phone: "Phone number is required",
      }));
      setPhone("");
    } else if (error.whatsapp !== "") {
      setError((pre) => ({
        ...pre,
        whatsapp: "Whatsapp number is required",
      }));
      setWhatsapp("");
    } else {
      sendData();
    }
  };

  const sendData = async () => {
    try {
      const response = await clients.post("/contact/update-contact", {
        id: id,
        address: address,
        email: email,
        whatsapp: whatsapp,
        phone: phone,
      },{
        withCredentials:true
      });
      if (response.status === 200) {
        navigate("/");

        toast.success("Contact Details Updated Successfully ");
      }
    } catch (err) {
      console.log(err);
 
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to update contact details");
      }
     
    }
  };

  return (
    <Fragment>
      <div className="manage-booking">
      <section className="contact_section layout_padding">
      <div className="container">
        {data.map((item) => {
          return (
            <>
              <div className="heading_container text-center">
                <h2>Contact Details</h2>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="featuredbox-number">
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-6 mx-auto">
                        <div className="featured-content">
                          <div class="cr">
                            <div className="featured-title">
                              <h3 class="card-title">ADDRESS</h3>
                            </div>
                            <div
                              className="featured-desc"
                              style={{
                                fontSize: "16px",
                                color: "black",
                                padding: "10px",
                              }}
                            >
                              <p class="small-desc">{item.address}</p>
                            </div>
                            <div class="go-corner">
                              <div class="go-arrow">→</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 mx-auto">
                        <div className="featured-content">
                          <div class="cr">
                            <div className="featured-title">
                              <h3 class="card-title">CONTACT NUMBER </h3>
                            </div>
                            <div
                              className="featured-desc"
                              style={{
                                fontSize: "16px",
                                color: "black",
                                padding: "10px",
                              }}
                            >
                              <p
                                class="small-desc"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <BsWhatsapp
                                  style={{
                                    fontSize: "30px",
                                    color: "green",
                                  }}
                                />
                                <span
                                  style={{
                                    fontSize: "16px",
                                    color: "black",
                                    marginLeft: "10px",
                                  }}
                                >
                                  {" "}
                                  {item.whatsapp}{" "}
                                </span>
                              </p>
                              <p
                                class="small-desc"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <FaPhone
                                  style={{
                                    fontSize: "30px",
                                    color: "blue",
                                  }}
                                />
                                <span
                                  style={{
                                    fontSize: "16px",
                                    color: "black",
                                    marginLeft: "10px",
                                  }}
                                >
                                  {" "}
                                  +91 {item.phone}{" "}
                                </span>
                              </p>
                            </div>
                            <div class="go-corner">
                              <div class="go-arrow">→</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 mx-auto">
                        <div className="featured-content">
                          <div class="cr">
                            <div className="featured-title">
                              <h3 class="card-title">EMAIL</h3>
                            </div>
                            <div
                              className="featured-desc"
                              style={{
                                fontSize: "16px",
                                color: "black",
                                padding: "10px",
                              }}
                            >
                              <p class="small-desc">
                                <Link to="">{item.email}</Link>
                              </p>
                            </div>
                            <div class="go-corner">
                              <div class="go-arrow">→</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact heading_container text-center">
                <div className="col-lg-12  mx-auto">
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleDatapass(item);
                    }}
                    style={{
                      backgroundColor: "#4CAF50",
                      borderRadius: "5px",
                    }}
                  >
                    Updated
                  </Button>
                </div>
              </div>
            </>
          );
        })}
        </div>
      </section>
      </div>

      <div>
        <Modal show={open} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Contact Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="update-form">
                <Form.Group>
                  <div className="up-name">
                    <div>
                      <FormLabel>Address</FormLabel>
                    </div>
                    <div style={{ marginTop: "20px" }} className="contactUpdate">
                      <TextField
                        label="Address"
                        id="outlined-basic"
                        variant="outlined"
                        name="address"
                        required
                        multiline
                        value={address}
                        className="contactUpdate"
                        sx={{
                          "& > :not(style)": { width: "30ch" },
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!error.address}
                        helperText={error.address}
                      />
                    </div>
                  </div>
                </Form.Group>

                <Form.Group>
                  <div className="up-name">
                    <div>
                      <FormLabel>Whatsapp Number</FormLabel>
                    </div>
                    <div style={{ marginTop: "20px" }}  className="contactUpdate">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        label="Whatsapp Number"
                        name="whatsapp"
                        required
                        value={whatsapp}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!error.whatsapp}
                        helperText={error.whatsapp}
                        inputProps={{ maxLength: 10 }}
                        className="contactUpdate"
                        sx={{
                          "& > :not(style)": { width: "30ch" },
                        }}
                        onKeyDown={(e) => {
                          const allowedKeys = [
                            "Backspace",
                            "ArrowLeft",
                            "ArrowRight",
                            "Delete",
                            "Tab",
                          ];
                          const allowedCharPattern = /^[0-9+]$/;

                          // Check if the pressed key is not allowed
                          if (
                            !allowedKeys.includes(e.key) &&
                            !allowedCharPattern.test(e.key)
                          ) {
                            e.preventDefault(); // Prevent the default action of the disallowed key
                          }
                        }}
                      />
                    </div>
                  </div>
                </Form.Group>

                <Form.Group>
                  <div className="up-name">
                    <div>
                      <FormLabel>Phone Number</FormLabel>
                    </div>
                    <div style={{ marginTop: "20px" }}  className="contactUpdate">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        label="Phone Number"
                        name="phone"
                        required
                        value={phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!error.phone}
                        helperText={error.phone}
                        inputProps={{ maxLength: 10 }}
                        className="contactUpdate"
                        sx={{
                          "& > :not(style)": { width: "30ch" },
                        }}
                        onKeyDown={(e) => {
                          const allowedKeys = [
                            "Backspace",
                            "ArrowLeft",
                            "ArrowRight",
                            "Delete",
                            "Tab",
                          ];
                          const allowedCharPattern = /^[0-9+]$/;

                          // Check if the pressed key is not allowed
                          if (
                            !allowedKeys.includes(e.key) &&
                            !allowedCharPattern.test(e.key)
                          ) {
                            e.preventDefault(); // Prevent the default action of the disallowed key
                          }
                        }}
                      />
                    </div>
                  </div>
                </Form.Group>

                <Form.Group>
                  <div className="up-name">
                    <div>
                      <FormLabel>Email</FormLabel>
                    </div>
                    <div  className="contactUpdate">
                      <TextField
                        id="outlined-multiline-static"
                        label="Email"
                        name="email"
                        required
                        variant="outlined"
                        value={email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!error.email}
                        helperText={error.email}
                        className="contactUpdate"
                        sx={{
                          "& > :not(style)": { width: "30ch" },
                        }}
                        onKeyDown={(e) => {
                          const allowedKeys = [
                            "Backspace",
                            "ArrowLeft",
                            "ArrowRight",
                            "Delete",
                            "Tab",
                          ];
                          const allowedCharPattern = /^[0-9a-z._@-]$/;

                          // Check if the pressed key is not allowed
                          if (
                            !allowedKeys.includes(e.key) &&
                            !allowedCharPattern.test(e.key)
                          ) {
                            e.preventDefault(); // Prevent the default action of the disallowed key
                          }
                        }}
                        style={{
                          marginTop: "10px",
                          width: "100%",
                        }}
                      />
                    </div>
                  </div>
                </Form.Group>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="contained" color="success" onClick={handleSubmit}>
              Save changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      
                   
             
        
    </Fragment>
  );
};

export default Contact;
