import React, { Fragment } from 'react'
import Adminhome from '../Home/Adminhome'

const Dashboard = () => {
  
  return (
  <Fragment>
   <Adminhome/>
  
  </Fragment>
  )
}

export default Dashboard