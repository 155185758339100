import React, { useEffect, useState } from "react";
import route from "./Compoents/Common/Routes/Routes";
import Login from "./Compoents/Pages/Login/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import {  Navigate, Route, Routes,useLocation, useNavigate } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import Header from "./Compoents/Common/Layout/Header/Header";
import ScrollToTop from './Compoents/Common/Layout/ScrollTop/Scrool'


function App() {
  const [admin, setAdmin] = useState(null);
  const location = useLocation();
  const navigate=useNavigate() 

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const expirationTime = localStorage.getItem("tokenExpiration");
      if (expirationTime && Date.now() > expirationTime) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        setAdmin(null); 
      } else {
     
        setAdmin(token); 
      }
    }
      else {
        setAdmin(null);
        navigate("/"); 
      }
    

  }, [navigate]);

  const headerPaths = [
    "/",
    "/admin/update-password",
    "/admin/products",
    "/admin/contact-details",
    "/admin/manage-products",
    "/admin/manage-booking",
  ];

 

  return (
    <div className="App">
       <ScrollToTop/>
      <Toaster toastOptions={{
    success: {
      style: {
        duration: 3000,
        background: 'green',
        color:"white"
      },
    },
    error: {
      style: {
        duration: 3000,
        background: 'red',
        color:"white"
      },
    },
  }} />
      {admin === null ?  
      <Login setAdmin={setAdmin} />
      :
      <>  
            {headerPaths.includes(location.pathname) && <Header setAdmin={setAdmin} />}
          <Routes>
        {route.map((e, index) => {
          return <Route key={index} path={e.path}  
          
          element={React.cloneElement(e.element, { setAdmin })}
          />;
        })}
      </Routes>
      </>

}
    </div>
  );
}

export default App;
