import React, { Fragment, useEffect, useState } from "react";
import "./ManageBooking.css";
import clients from "../../Common/Client/Clients";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import sea from "../../../Assets/Image/sea.png";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import toast from "react-hot-toast";

const ManageBooking = () => {
  const [booking, setBooking] = useState([]);
  const [orderIds, setOrderIds] = useState([]);
  const [error, setError] = useState({
    orderIds: "",
  });

  useEffect(() => {
    getBooking();
  }, []);

  const getBooking = async () => {
    try {
      const resoponse = await clients.get("/booking/get-booking",{
        withCredentials:true
      });
      if (resoponse.status === 200) {
        setBooking(resoponse.data);
      }
    } catch (error) {
      console.log("error");
    }
  };

  const handleOrderIdChange = (itemId, purchaseId, newOrderId) => {
    const key = `${itemId}-${purchaseId}`;
   let value=newOrderId.trim()
   let updatedOrderIds;

    // Validate and set errors
    if(value===""){
      setError((prevErrors) => ({
        ...prevErrors,
        [key]: "",
      }));
      const update = orderIds.filter((value) => 
        !(value.itemId === itemId && value.purchaseId === purchaseId)
      );
      setOrderIds(update)
    }else  if (value.length < 5) {
      setError((prevErrors) => ({
        ...prevErrors,
        [key]: "Please enter the Order ID",
      }));
    }else  {
      setError((prevErrors) => ({
        ...prevErrors,
        [key]: "",
      }));
     
         // Check if this orderId already exists
    const existingOrderIndex = orderIds.findIndex(
      (order) => order.itemId === itemId && order.purchaseId === purchaseId
    );

    if (existingOrderIndex >= 0) {
      // Update existing order ID
      updatedOrderIds = [...orderIds];
      updatedOrderIds[existingOrderIndex] = {
        itemId,
        purchaseId,
        orderId: value,
      };
    } else {
      // Add new order ID
      updatedOrderIds = [
        ...orderIds,
        { itemId, purchaseId, orderId: value },
      ];
    }

    setOrderIds(updatedOrderIds);
  
    }
  };

  
  const deleteData = async (id) => {
    try {
      if (window.confirm("Do you wnat to delete the Booking")) {
        const deleteApi = await clients.post("/booking/delete-booking", { id },{
          withCredentials:true
        });
        if (deleteApi.status === 204) {
          const deleteData = booking.filter((value) => value._id !== id);
          setBooking(deleteData);
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to delete products details");
      }
    }
  };

  const handleUpdateBooking = async (id, purchaseId) => {

  
      // Find the order ID for the given itemId and purchaseId
      const orderIdObj = orderIds.find(
        (order) => order.itemId === id && order.purchaseId === purchaseId
      );
  
    try {
        const resoponse = await clients.post("/booking/manage-booking", {
          id,
          purchaseId,
          status: "Delivered",
          orderIds:orderIdObj ? orderIdObj.orderId : ""
        },{
          withCredentials:true
        });
        if (resoponse.status === 200) {
          console.log(resoponse.data);
          const updateBooking = booking.map((value) => {
            if (value._id === id) {
              return {
                ...value,
                purchase: resoponse.data.purchase,
              };
            }
            return value;
          });
          setBooking(updateBooking);

      }
      
      
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to update products details");
      }
    
    }
  };


  console.log(orderIds)
  return (
    <Fragment>
      {booking.length === 0 ? (
        <div className="manage-bookings">
          <div className="notfoundbooking">
            <img src={sea} alt="" />
            <p>No Booking Found</p>
          </div>
        </div>
      ) : (
        <div className="manage-booking">
          <div className="products-table">
            <p>Manage Booking</p>
            <table class="table table-success table-striped">
              <thead>
                <tr>
                  <th width="10%" scope="col">
                    Name
                  </th>
                  <th width="10%" scope="col">
                    Email
                  </th>
                  <th width="10%" scope="col">
                    Phone Number
                  </th>
                  <th width="20%" scope="col">
                    Address
                  </th>
                  <th width="41%" scope="col">
                    purchase
                  </th>
                  <th width="9%" scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {booking.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.phoneNumber}</td>
                      <td>
                        <div className="address" style={{
                          textAlign:"left"
                        }}>
                          {item.address.doorno}, {item.address.street},<br/>{" "}
                          {item.address.area}
                          {item.address.landmark &&
                            `, ${item.address.landmark}`},<br/>
                          {item.address.district},<br/>{item.address.state} -{" "}
                          {item.address.pincode}
                        </div>
                      </td>
                      <td>
                        <table>
                          <tr>
                            <th width="11%">Product Name</th>
                            <th width="11%"> Quantity</th>
                            <th>Order Id</th>
                            <th width="11%">status</th>
                          </tr>

                          {item.purchase.map((value, index) => {
                            return (
                              <tr key={index}>
                                <td>{value.productName}</td>
                                <td>{value.quantity}</td>
                                <td>
                                {
                                    value.status === "Delivered" ? <span style={{
                                      color: "green",
                                      
                                      textAlign:"center"
                                    }}>{value.orderIds}</span> :<TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    className="form"
                                    onKeyDown={(e) => {
                                      const allowedKeys = [
                                        "Backspace",
                                        "ArrowLeft",
                                        "ArrowRight",
                                        "Delete",
                                        "Tab",
                                      ];
                                      const allowedCharPattern = /^[0-9a-zA-Z]$/;
            
                                      // Check if the pressed key is not allowed
                                      if (
                                        !allowedKeys.includes(e.key) &&
                                        !allowedCharPattern.test(e.key)
                                      ) {
                                        e.preventDefault(); // Prevent the default action of the disallowed key
                                      }
                                    }}
                                    label="Order Id"
                                    error={!!error[`${item._id}-${value._id}`]}
                                    helperText={error[`${item._id}-${value._id}`]} 
                                    name="orderIds"
                                    value={value.orderIds}
                                    inputProps={{ maxLength: 35 }}
                                    sx={{
                                      width: 200,
                                    }}
                                    onChange={(e) =>
                                      handleOrderIdChange(
                                        item._id,
                                        value._id,
                                        e.target.value
                                      )
                                    }
                                  />
                                  
                                }
                                  
                                </td>
                                <td>
                                  {value.status === "Not Delivered" ? (
                                    <Button
                                      variant="contained"
                                      color="error"
                                      disabled={
                                        !orderIds.find(
                                          (order) =>
                                            order.itemId === item._id &&
                                            order.purchaseId === value._id
                                        )
                                      }
                                      onClick={() => {
                                        handleUpdateBooking(
                                          item._id,
                                          value._id
                                        );
                                      }}
                                      style={{
                                        width: "120px",
                                        height: "30px",
                                        padding: "0px",
                                      }}
                                    >
                                      {value.status}
                                    </Button>
                                  ) : (
                                    <span
                                      style={{
                                        color: "green",
                                        
                                        textAlign:"center"
                                      }}
                                    >
                                      {value.status}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </td>
                      <td>
                        <span onClick={() => deleteData(item._id)}>
                          <DeleteSharpIcon />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ManageBooking;
