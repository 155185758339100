import { Box, Button, TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import { IconButton } from "@mui/material";
import Stack from "@mui/material/Stack";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { RiLockPasswordLine } from "react-icons/ri";
import bcrypt from "bcryptjs"; 
import React, { Fragment, useEffect, useState } from "react";
import clients from "../../Common/Client/Clients";

const ChangePassword = (props) => {
  const { setAdmin } = props;
  console.log(setAdmin)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [error, setError] = useState({
    email: "",
    password: "",
    confirmpassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);


  console.log(data)
  useEffect(() => {
    adminData();
  }, []);

  const adminData = async () => {
    try {
      const response = await clients.get("/admin/get-signup");
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(data)

  const errorMessage = (filedName, filedValue) => {
    let message;
    if (filedName) {
      if (filedValue === "") {
        message = "";
      }
    }
    if (filedName === "email") {
      const emailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{2,}@[a-zA-Z-]+\.[a-zA-Z-]{2,}$/;
      if (!emailRegex.test(filedValue)) {
        message = `${filedName} is Invalid`;
      } else {
        message = "";
      }
    }

    if (filedName === "password") {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!passwordRegex.test(filedValue)) {
        message = `${filedName} is Invalid`;
      }else {
        message = "";
      }
    }

    if (filedName === "confirmpassword") {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!passwordRegex.test(filedValue)) {
        message = `${filedName} is Invalid`;
      } else if (filedValue !== password) {
        message = `${filedName} and Password is not same`;
      } else {
        message = "";
      }
    }
    return { message: message };
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;
    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    } else {
      setConfirmPassword(value);
    }
  };
  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (!value) {
      setError((prevState) => ({
        ...prevState,
        [name]: `${name} enter the field`,
      }));
    }
  };

  const handleSubmit =async (e) => {
    e.preventDefault();
    if (email === "" || password === "" || confirmpassword === "") {
      setError((prevState) => ({
        ...prevState,
        email: "Email is required",
        password: "Password is required",
        confirmpassword: "Confirm Password is required",
      }));
      setConfirmPassword("");
      setEmail("");
      setPassword("");
    } else if (
      error.email !== "" ||
      error.password !== "" ||
      error.confirmpassword !== ""
    ) {
      setError((prevState) => ({
        ...prevState,
        email: "Email is required",
        password: "Password is required",
        confirmpassword: "Confirm Password is required",
      }));
      setConfirmPassword("");
      setEmail("");
      setPassword("");
    } else if (email !== "") {
      const existingEmail = data.find((value) => value.email === email);
      if (existingEmail) {
        const oldPasswordHash = existingEmail.password;

      
        const isSamePassword = await bcrypt.compare(password, oldPasswordHash);
  
        if (isSamePassword) {
          setError((prevState) => ({
            ...prevState,
            password: "Password is the same as the old one",
          }));
        }else if (password !== confirmpassword){
          setError((prevState) => ({
            ...prevState,confirmpassword:"Passwords are not same"}))
        }else{
        getUpdate();
        }
      } else {
        setError((prevState) => ({
          ...prevState,
          email: "Email is not exist",
        }));
      }
    }
  };

  const getUpdate = async () => {
    try {
      const res = await clients.post("/admin/update-signup", {
        email,
        password,
      },{
        withCredentials:true
      });
      if (res.status === 200) {
        localStorage.removeItem("token");
        setAdmin(null)

        setConfirmPassword("");
        setPassword("");
        setEmail("");
      }
    } catch (err) {
      setError((prevError) => ({
        ...prevError,

        email: "Email is  not valid ",
      }));
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to update login details");
      }
    }
   
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <Fragment>
    <div className="password-full-background">
  <div className="password-overlay">
    <div className="password-form-container">
      <p className="password-icon">
        <RiLockPasswordLine />
      </p>
      <p className="password-heading">Change Password</p>

      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1.5 },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          className="password-form-input"
          id="standard-basic"
          label="Email"
          variant="standard"
          name="email"
          value={email}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={error.email ? error.email : ""}
          error={!!error.email}
          onKeyDown={(e) => {
            const allowedKeys = [
              "Backspace",
              "ArrowLeft",
              "ArrowRight",
              "Delete",
              "Tab",
            ];
            const allowedCharPattern = /^[0-9a-z._@-]$/;

            if (
              !allowedKeys.includes(e.key) &&
              !allowedCharPattern.test(e.key)
            ) {
              e.preventDefault();
            }
          }}
        />

        <TextField
          className="password-form-input"
          id="standard-basic"
          label="Password"
          variant="standard"
          value={password}
          type={showPassword ? "text" : "password"}
          name="password"
          onChange={handleChange}
          inputProps={{ maxLength: 12 }}
          onBlur={handleBlur}
          helperText={error.password ? error.password : ""}
          error={!!error.password}
          onKeyDown={(e) => {
            if (e.key === " ") {
              e.preventDefault();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          className="password-form-input"
          id="standard-basic"
          label="Confirm Password"
          variant="standard"
          value={confirmpassword}
          type={showPassword ? "text" : "password"}
          name="confirmpassword"
          onChange={handleChange}
          inputProps={{ maxLength: 12 }}
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.key === " ") {
              e.preventDefault();
            }
          }}
          helperText={
            error.confirmpassword ? error.confirmpassword : ""
          }
          error={!!error.confirmpassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <div className="password-form-button">
          <Stack spacing={2} direction="row" justifyContent="center">
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </Stack>
        </div>
      </Box>
    </div>
  </div>
</div>

    </Fragment>
  );
};

export default ChangePassword;
