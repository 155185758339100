import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Fragment, useState } from "react";
import { Box, FormLabel, Stack, TextField } from "@mui/material";
import "./Products.css";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import clients from "../../Common/Client/Clients";
import Loader from "../../Common/Loader/Loader";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const Products = () => {
  const [productName, setProductName] = useState("");
  const [image, setImage] = useState(null);
  const [error, setError] = useState({
    productName: "",
    image: "",
    feature: "",
    nextId: "",
    keyFeatures: "",
  });
  const [images, setImages] = useState([]);
  const [des, setDes] = useState("");
  const [erros, setErrors] = useState({ description: "", images: "" });
  const [inputField, setInputField] = useState([]);
  const [nextId, setNextId] = useState(0);
  const [features, setFeatures] = useState("");
  const [showImage, setShowImage] = useState(null);
  const [arrayImage, setArrayImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setError((prevError) => ({
        ...prevError,
        [name]: `${name} is required`,
      }));
    }
  };
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value.trimLeft();
    const err = errorMessage(name, value).message;

    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    setProductName(value);
  };
  const errorMessage = (fieldName, fieldValue) => {
    let message;
    if (fieldName) {
      if (fieldValue === "") {
        message = "";
      }
    }

    if (fieldName === "feature") {
      if (fieldValue.length < 10) {
        message = "Feature must be at least 10 characters long";
      } else {
        message = "";
      }
    }

    if (fieldName === "productName") {
      if (fieldValue.length < 3) {
        message = "Product name must be at least 3 characters long";
      } else {
        message = "";
      }
    }

    return { message: message };
  };
  const handleImageChange = (e) => {
    const image = e.target.files[0];
    if (image) {
      const fileType = image.type;
      if (fileType.startsWith("image/")) {
        var reader = new FileReader();
        reader.onload = function () {
          setImage(image);
          setShowImage(reader.result);
        };
        reader.readAsDataURL(image);
        setError((prev) => {
          return { ...prev, image: "" };
        });
      } else {
        setError((prev) => {
          return { ...prev, image: "Please select an image file" };
        });
      }
    }
  };

  const imageChange = (event) => {
    const file = event.target.files;
    Array.from(file).forEach((selectedFile) => {
      if (selectedFile) {
        const fileType = selectedFile.type;
        if (fileType.startsWith("image/")) {
          var reader = new FileReader();
          reader.onload = function () {
            setImages((pre) => {
              return [...pre, selectedFile];
            });
            setArrayImage((pre) => {
              return [...pre, reader.result];
            });
          };
          reader.readAsDataURL(selectedFile);
          setErrors((prev) => {
            return { ...prev, images: "" };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, images: "Please select an image file" };
          });
        }
      }
    });
  };
  const handleBlurDes = (e) => {
    const { name, value } = e.target;
    if (value.trim() === "") {
      setErrors((prev) => ({ ...prev, description: `${name} is required` }));
    }
  };

  const handleFeatures = (e) => {
    const name = e.target.name;
    const value = e.target.value.trimLeft();
    const err = errorMessage(name, value).message;

    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    setFeatures(value);
  };

  const handelDesChange = (e) => {
    const { name, value } = e.target;

    const err = errorMessages(name, value).message;

    setErrors((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    setDes(value);
  };
  const errorMessages = (fieldName, fieldvalue) => {
    let message;
    if (fieldName === "description") {
      if (fieldvalue === "") {
        message = "";
      }
    }

    if (fieldName === "description") {
      if (fieldvalue.length < 50) {
        message = "Description should be at least 50 characters long";
      } else {
        message = "";
      }
    }

    if (fieldName === "keyFeatures") {
      if (fieldvalue === "") {
        message = "";
      } else if (fieldvalue.length < 10) {
        message = "Key Features should be at least 10 characters long";
      } else {
        message = "";
      }
    }
    return { message: message };
  };

  const handleKeyDown = (e) => {
    // Prevent leading space
    if (e.key === ' ' && e.target.selectionStart === 0) {
      e.preventDefault();
    }
  };
  const handleAddInput = () => {
    if (error.keyFeatures === "") {
      setNextId(nextId + 1);
      setInputField((prev) => {
        return [...prev, { id: nextId, value: "" }];
      });
    }
    if (nextId === 4) {
      setError((pre) => ({ ...pre, nextId: "" }));
    }
  };
  const handleDescriptionChange = (e, id) => {
    const value = e.target.value.trimLeft();
    const name = e.target.name;
    const err = errorMessages(name, value).message;
    setError((prevError) => ({ ...prevError, keyFeatures: err }));
    if (Array.isArray(inputField)) {
      const updateInputFields = inputField.map((field) =>
        field.id === id ? { ...field, value: value } : field
      );

      setInputField(updateInputFields);
    } else {
      console.error("inputField is not an array:", inputField);
    }
  };
  const deleteInputField = (id) => {
    const deleteInputFieldS = inputField.filter((value) => value.id !== id);
    setNextId(nextId - 1);
    setInputField(deleteInputFieldS);
  };
  const uploadProducts = () => {
    if (
      productName === "" ||
      image === null ||
      features === "" ||
      des === "" ||
      images.length === 0
    ) {
     toast.error("Fill the all field")
    } else if (
      error.image !== "" ||
      error.productName !== "" ||
      error.feature !== "" ||
      erros.description !== "" ||
      erros.images !== "" ||
      error.keyFeatures !== "" ||
      error.nextId !== ""
    ) {
  toast.error("Fix the all error")
    } else if (nextId < 5) {
      setError((pre) => ({ ...pre, nextId: "you need to add 5 features" }));
      toast.error("You need to add 5 features")
    } else if (inputField.some((field) => field.value === "")) {
      setError((pre) => ({
        ...pre,
        keyFeatures: "Please fill in all Features",
      }));
      toast.error("Please fill in all Features")
    } else {
      setLoading(true);
      const input = [{ value: features }, ...inputField];
      const formData = new FormData();
      formData.append("name", productName);
      formData.append("description", des);
      formData.append("mainImage", image);
      input.forEach((valueDes) => {
        formData.append("desvalue", valueDes.value);
      });
      images.forEach((valueImage) => {
        formData.append("subImages", valueImage);
      });
      clients
        .post("/product/submit-product", formData,{
          withCredentials:true
        })
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            toast.success("Products successfully added");
            navigate("/");
            setDes("");
            setImage([]);
            setImages([]);
            setProductName("");
            setInputField([]);
            setFeatures("");
            setNextId(0);
          } else {
            setError((preError) => ({
              ...preError,
              productName: "Product Name is required",
              image: "Products Image is required",
            }));
            setErrors((prevError) => ({
              ...prevError,
              description: "Description is required",
              images: "Multiple Image is Reqiured",
            }));
            setProductName("");
            setDes("");
            setImage([]);
            setImages([]);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            setLoading(false)
            toast.error("Login again");
          } else {
            setLoading(false)
            toast.error("Failed to update login details");
          }
          
        });
      
    }
  };

  return (
    <Fragment>
      <div className="admin-products">
        <div className="products-form">
          <div className="products-main">
            <p>Products Main Details</p>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1 },
              }}
              noValidate
              autoComplete="off"
            >
              <div className="products-main-form">
                <div>
                  <TextField
                    label="Product Name"
                    id="outlined-basic"
                    variant="outlined"
                    name="productName"
                    required
                    value={productName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={error.productName ? error.productName : ""}
                    error={!!error.productName}
                 
                    className="text"
                    inputProps={{ maxLength: 30 }}
                    onKeyDown={(e) => {
                      handleKeyDown(e)
                      const allowedKeys = [
                        "Backspace",
                        "ArrowLeft",
                        "ArrowRight",
                        "Delete",
                        "Tab",
                        " ",
                      ];
                      const allowedCharPattern = /^[0-9A-Za-z._-]$/;

                      // Check if the pressed key is not allowed
                      if (
                        !allowedKeys.includes(e.key) &&
                        !allowedCharPattern.test(e.key)
                      ) {
                        e.preventDefault(); // Prevent the default action of the disallowed key
                      }
                    }}
                  />
                </div>
                <div className="products-image-button">
                  <FormLabel>
                    product Main Image
                    <p style={{ fontSize: "12px", color: "black" }}>
                      (choose One Image)
                    </p>
                  </FormLabel>
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    style={{ marginTop: "10px" }}
                    disabled={image !== null}
                  >
                    Product Image
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleImageChange}
                      accept="image/*"
                    />
                  </Button>
                  {error.image && (
                    <div
                      style={{
                        color: "red",
                        marginLeft: "20px",
                        fontSize: "13px",
                      }}
                    >
                      {error.image}
                    </div>
                  )}
                  {showImage !== null ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        width: "200px",
                      }}
                    >
                      <img
                        src={showImage}
                        alt=""
                        width="100px"
                        height="100px"
                        style={{
                          marginTop: "0px",
                        }}
                      />
                      <Button
                        color="error"
                        variant="contained"
                        onClick={() => {
                          setShowImage(null);
                          setImage(null);
                        }}
                        style={{
                          marginTop: "0px",
                          textAlign: "center",
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </Box>
          </div>
          <div className="products-sub">
            <div className="product-details">
              <p>Products Sub Details</p>

              <Box sx={{ m: 1 }}>
                <div className="products-details">
                  <div className="products-des">
                    <div>
                      <FormLabel>Description</FormLabel>
                    </div>

                    <TextField
                      id="outlined-multiline-static"
                      label="Description"
                      name="description"
                      required
                      multiline
                      variant="outlined"
                      onKeyDown={handleKeyDown}
                      value={des}
                      onChange={handelDesChange}
                      onBlur={handleBlurDes}
                      error={!!erros.description}
                      helperText={erros.description ? erros.description : ""}
                      className="texts"
                    />
                  </div>
                  <div
                    className="product-description"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="add-description">
                      <FormLabel>Salient Features</FormLabel>
                      <Button
                        variant="contained"
                        onClick={handleAddInput}
                        color="success"
                        style={{ height: "30px" }}
                        disabled={!features.trim() || !!error.keyFeatures || inputField.some(field => field.value === "")}
                      >
                        Add
                      </Button>
                    </div>
                    {error.nextId && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "15px",
                        }}
                      >
                        {error.nextId}
                      </div>
                    )}

                    <TextField
                      id="outlined-multiline-static"
                      label="Salient Features"
                      name="feature"
                      required
                      multiline
                      variant="outlined"
                      onChange={handleFeatures}
                      onKeyDown={handleKeyDown}
                      value={features}
                      onBlur={handleBlur}
                      error={!!error.feature}
                      helperText={error.feature ? error.feature : ""}
                      className="texts"
                    />
                    {inputField.map((value, index) => {
                      return (
                        <div key={value.id}>
                          <TextField
                            className="texts"
                            multiline
                            id="outlined-multiline-static"
                            label="Salient Features"
                            name="keyFeatures"
                            variant="outlined"
                            onKeyDown={handleKeyDown}
                            value={value.value}
                            onChange={(e) => {
                              handleDescriptionChange(e, value.id);
                            }}
                          />
                          <Button
                            variant="contained"
                            color="error"
                            style={{
                              marginTop: "20px",
                              marginLeft: "15px",
                              width: "20px",
                            }}
                            onClick={() => {
                              deleteInputField(value.id);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      );
                    })}
                    {error.keyFeatures && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "15px",
                        }}
                      >
                        {error.keyFeatures}
                      </div>
                    )}
                  </div>
                  <div className="product-images">
                    <FormLabel>
                      product sub Image
                      <p style={{ fontSize: "12px", color: "black" }}>
                        (choose multiple Image)
                      </p>
                    </FormLabel>
                    <div className="images1">
                      <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                        style={{ marginTop: "5px" }}
                        disabled={arrayImage.length !== 0}
                      >
                        Product Image
                        <VisuallyHiddenInput
                          type="file"
                          onChange={imageChange}
                          accept="image/*"
                          multiple
                        />
                      </Button>
                      {arrayImage.length !== 0 ? (
                        <>
                          {" "}
                          <div className="subimage-show">
                            {arrayImage.map((value) => {
                              return (
                                <img
                                  src={value}
                                  alt=""
                                  width="70px"
                                  height="70px"
                                />
                              );
                            })}
                          </div>
                          <Button
                            color="error"
                            variant="contained"
                            onClick={() => {
                              setArrayImage([]);
                              setImages([]);
                            }}
                            style={{
                              marginTop: "10px",
                              textAlign: "center",
                            }}
                          >
                            Delete
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    {erros.images && (
                      <div
                        style={{
                          color: "red",
                          marginLeft: "20px",
                          fontSize: "13px",
                        }}
                      >
                        {erros.images}
                      </div>
                    )}
                  </div>

                  <div className="add-product-button">
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "rgb(98, 210, 162)",
                        }}
                        onClick={uploadProducts}
                      >
                        Add products
                      </Button>
                    </Stack>
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </Fragment>
  );
};
export default Products;
