import React, { Fragment, useEffect, useState } from "react";
import "./Adminhome.css";
import clients from "../../Common/Client/Clients";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Adminhome = () => {
  const [product, setProduct] = useState([]);
  const [booking, setBooking] = useState([]);
  const [signupData, setSignupData] = useState([]);

  useEffect(() => {
    fetchProducts();
    fetchBooking();
    fetchAdmin();
  }, []);

  const fetchProducts = async () => {
    try {
      const respose = await clients.get("/product/get-product");
      setProduct(respose.data);
    } catch (error) {
      console.log("Products is not found");
    }
  };
  const fetchBooking = async () => {
    try {
      const resoponse = await clients.get("/booking/get-booking",{
        withCredentials:true
      });
      if (resoponse.status === 200) {
        setBooking(resoponse.data);
      }
    } catch (error) {
      console.log("error");
    }
  };
  const fetchAdmin = async () => {
    try {
      const response = await clients.get("/admin/get-signup");
      if (response.status === 200) {
        setSignupData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const data = {
    labels: ["Signups", "Products", "Bookings"],
    datasets: [
      {
        label: "Count",
        data: [signupData.length, product.length, booking.length],
        backgroundColor: [
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.label}: ${context.raw}`;
          },
        },
      },
    },
  };
  return (
    <Fragment>
      <div style={{ marginTop: "60px" }}>
        <div className="adminhome-container">
          <div className="summary-card">
            <div className="summary-title">Total Admin</div>
            <div className="summary-value">{signupData.length}</div>
          </div>
          <div className="summary-card">
            <div className="summary-title">Total Products</div>
            <div className="summary-value">{product.length}</div>
          </div>
          <div className="summary-card">
            <div className="summary-title">Total Bookings</div>
            <div className="summary-value">{booking.length}</div>
          </div>
        </div>
        <div className="chart-container">
          <Bar
            data={data}
            options={options}
            style={{
              maxWidth: "800px",
              maxHeight: "400px",
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Adminhome;
