import ChangePassword from "../../Pages/Change-password/ChangePassword"
import Contact from "../../Pages/Contact/Contact"
import Dashboard from "../../Pages/Dashboard/Dashboard"
import ManageBooking from "../../Pages/Manage-Booking/ManageBooking"
import ManageProduct from "../../Pages/Manage-products/ManageProduct"
import NotFound from "../../Pages/Notfound/NotFound"
import Products from "../../Pages/Products/Products"


const route=[
    {path:"/",element:<Dashboard/>},
    {path:"/admin/update-password",element:<ChangePassword/>},
    {path:"/admin/products",element:<Products/>},
    {path:"/admin/contact-details", element:<Contact/>},
    {path:"/admin/manage-products",element:<ManageProduct/>},
    {path:"/admin/manage-booking",element:<ManageBooking/>},
    {path:"*",element:<NotFound/>},
]

export default route