import React, { Fragment, useEffect, useState } from "react";
import "./ManageProduct.css";
import clients from "../../Common/Client/Clients";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import EditIcon from "@mui/icons-material/Edit";
import toast from "react-hot-toast";
import { Button, styled } from "@mui/material";
import { FormLabel, TextField } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import sea from "../../../Assets/Image/sea.png";
import Loader from "../../Common/Loader/Loader";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const ManageProduct = () => {
  const [products, setProduct] = useState([]);
  const [id, setId] = useState(null);
  const [showAddImage, setShowAddImage] = useState(0);
  const [showImageButton, setShowImageButton] = useState(0);
  const [open, setOpen] = useState(false);
  const [inputField, setInputField] = useState([]);
  const [nextId, setNextId] = useState(0);
  const [name, setName] = useState("");
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [features, setFeatures] = useState([]);
  const [errors, setErrors] = useState({
    productName: "",
    image: "",
    description: "",
    images: "",
    features: "",
    keyFeatures: "",
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showImage, setShowImage] = useState(null);
  const [arrayImage, setArrayImage] = useState([]);

  useEffect(() => {
    fetchDatas();
    setNextId(features.length);
  }, [features]);

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setErrors((prev) => ({ ...prev, [name]: `${name} field is required` }));
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value.trimLeft();
    const err = errorMessage(name, value).message;
    if (err !== "") {
      setErrors((prev) => ({ ...prev, [name]: err }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
    if (name === "productName") {
      setName(value);
    } else if (name === "description") {
      setDescription(value);
    }
  };

  const errorMessage = (name, value) => {
    let message;
    if (name === "features") {
      if (value === "") {
        message = "Features field is required";
      } else if (value.length < 10) {
        message = "Features field must be at least 10 characters long";
      } else {
        message = "";
      }
      return { message: message };
    }
    if (name) {
      if (value === "") {
        message = "";
      }

      if (name === "keyfeatures") {
        if (value.length < 10) {
          message = "Key Features must be at least 10 characters long";
        } else {
          message = "";
        }
      }

      if (name === "productName") {
        if (value.length < 3) {
          message = "Product name must be at least 3 characters long";
        } else {
          message = "";
        }
      }

      if (name === "description") {
        if (value.length < 50) {
          message = "Description must be at least 50 characters long";
        } else {
          message = "";
        }
      }
      return { message: message };
    }
  };
  const handleAddInput = () => {
    if (errors.keyFeatures === "") {
      setNextId(nextId + 1);
      setInputField((prev) => {
        return [...prev, { id: nextId, value: "" }];
      });
    }

    if (nextId === 4) {
      setErrors((prev) => ({ ...prev, features: "" }));
    }
  };

  const deleteInputField = (id) => {
    const deleteInputFieldS = inputField.filter((value) => value.id !== id);
    setNextId(nextId - 1);
    setInputField(deleteInputFieldS);
  };
  const handleClose = () => {
    setOpen(false);
    setShowAddImage(0);
    setShowImageButton(0);
    setInputField([]);
    setNextId(0);
    setId(null);
    setErrors({
      productName: "",
      image: "",
      description: "",
      images: "",
      features: "",
      keyFeatures: "",
    });
  };
  const fetchDatas = async () => {
    try {
      const respose = await clients.get("/product/get-product");
      setProduct(respose.data);
    } catch (error) {
      console.log("Products is not found");
    }
  };
  const updateData = (id, value) => {
    setOpen(true);
    setName(value.name);
    setImage(value.mainImage);
    setDescription(value.description);
    setFeatures(value.desvalue);
    setImages(value.subImages);
    setId(id);
  };

  const deleteData = async (id) => {
    try {
      if (window.confirm("Do you wnat to delete the Products")) {
        const deleteApi = await clients.post("/product/delete-product", { id },{
          withCredentials:true
        });
        if (deleteApi.status === 204) {
          const deleteData = products.filter((value) => value._id !== id);
          setProduct(deleteData);
        }
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to delete product details");
      }
    }
  };
  const imageChange = (event) => {
    const file = event.target.files;
    Array.from(file).forEach((selectedFile) => {
      if (selectedFile) {
        const fileType = selectedFile.type;
        if (fileType.startsWith("image/")) {
          var reader = new FileReader();
          reader.onload = function () {
            setImages((pre) => {
              return [...pre, selectedFile];
            });
            setArrayImage((pre) => {
              return [...pre, reader.result];
            });
          };
          reader.readAsDataURL(selectedFile);
          setErrors((prev) => {
            return { ...prev, images: "" };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, images: "Please select an image file" };
          });
        }
      }
    });
  };
  const handleImageChange = (e) => {
    const image = e.target.files[0];
    if (image) {
      const fileType = image.type;
      if (fileType.startsWith("image/")) {
        var reader = new FileReader();
        reader.onload = function () {
          setImage(image);
          setShowImage(reader.result);
        };
        reader.readAsDataURL(image);
        setErrors((prev) => {
          return { ...prev, image: "" };
        });
      } else {
        setErrors((prev) => {
          return { ...prev, image: "Please select an image file" };
        });
      }
    }
  };
  const handleDescriptionChange = (e, id) => {
    const value = e.target.value.trimLeft();
    const name = e.target.name;
    const err = errorMessage(name, value).message;
    setErrors((prevError) => ({ ...prevError, keyFeatures: err }));

    const updateInputFields = inputField.map((field) =>
      field.id === id ? { ...field, value: value } : field
    );

    setInputField(updateInputFields);
  };

  const handleDelete = (index) => {
    const newFeatures = [...features];
    newFeatures.splice(index, 1);
    setFeatures(newFeatures);
    setNextId(nextId - 1);
    setErrors((prevError) => ({ ...prevError, features: "" }));
  };
  const handleFeatureChange = (e, index) => {
    const value = e.target.value.trimLeft();
    const name = e.target.name;
    const err = errorMessage(name, value).message;
    setErrors((prevError) => ({ ...prevError, features: err }));

    const newFeatures = [...features];
    newFeatures[index] = value;
    setFeatures(newFeatures);
  };

  const updateProduct = () => {
    if (name === "") {
      setErrors((prev) => ({
        ...prev,
        productName: "Product name is required",
      }));
    } else if (image === null) {
      setErrors((prev) => ({ ...prev, image: "Please select an image file" }));
    } else if (description === "") {
      setErrors((prev) => ({
        ...prev,
        description: "Description is required",
      }));
    } else if (nextId < 5) {
      setErrors((prev) => ({
        ...prev,
        features: "At least 5 features are required",
      }));
    } else if (images.length === 0) {
      setErrors((prev) => ({ ...prev, images: "Please select an image file" }));
    }else if ( inputField.some(field => field.value === "")){
      setErrors((prev) => ({ ...prev, features: "All features are required" }))
    } else {
      setLoading(true);
      const inputFields = inputField.map((value) => {
        return value.value;
      });
      let input = [...features, ...inputFields];
      const formData = new FormData();
      formData.append("id", id);
      formData.append("name", name);
      formData.append("description", description);
      formData.append("mainImage", image);
      input.forEach((valueDes) => {
        formData.append("desvalue", valueDes);
      });
      images.forEach((valueImage) => {
        formData.append("subImages", valueImage);
      });
    
      clients
        .post("/product/manage-product", formData,{
          withCredentials:true
        })
        .then((res) => {
          if (res.status === 200) {
            const updateProduct = products.map((value) => {
              if (value._id === id) {
                return {
                  ...value,
                  name: res.data.name,
                  description: res.data.description,
                  mainImage: res.data.mainImage,
                  desvalue: res.data.desvalue,
                  subImages: res.data.subImages,
                };
              }
              return value;
            });
            setProduct(updateProduct);
            setLoading(false);
            toast.success("Products update successfully ");
            navigate("/");
            setOpen(false);
            setShowAddImage(0);
            setShowImageButton(0);
            setInputField([]);
            setNextId(0);
            setId(null);
            setErrors({
              productName: "",
              image: "",
              description: "",
              images: "",
              features: "",
              keyFeatures: "",
            });
          }
        })
        .catch((err) => {       if (err.response && err.response.status === 401) {
          setLoading(false);
          toast.error("Login again");
        } else {
          setLoading(false);
          toast.error("Failed to update product details");
        }});
    }
  };

  const handleKeyDown = (e) => {
    // Prevent leading space
    if (e.key === ' ' && e.target.selectionStart === 0) {
      e.preventDefault();
    }
  };

  return (
    <Fragment>
      {products.length === 0 ? (
        <div className="manage-bookings">
          <div className="notfoundbooking">
            <img src={sea} alt="" />
            <p>No Products Found</p>
            <Button
              variant="outlined"
              color="success"
              onClick={() => navigate("/admin/products")}
            >
              Add Products
            </Button>
          </div>
        </div>
      ) : (
        <div className="manage-products">
          <div className="products-table">
            <p>Manage Products</p>
            <table
              class="table table-success table-striped"
              style={{
                textAlign: "center",
              }}
            >
              <thead>
                <tr>
                  <th width="40%" scope="col">
                    product Name
                  </th>
                  <th width="40%" scope="col">
                    Product Main Image
                  </th>
                  <th width="20%" scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {products.map((productValue) => {
                  return (
                    <tr key={productValue._id}>
                      <td>{productValue.name}</td>
                      <td>
                        <img
                          src={productValue.mainImage}
                          alt="productMainImage"
                          
                        />
                      </td>
                      <td>
                        <div className="icons">
                          <span
                            onClick={() =>
                              updateData(productValue._id, productValue)
                            }
                          >
                            <EditIcon />
                          </span>

                          <span onClick={() => deleteData(productValue._id)}>
                            <DeleteSharpIcon />
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div>
            <Modal show={open} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Product Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <div className="update-form">
                    <Form.Group>
                      <div className="up-name" style={{ width: "100%" }}>
                        <div>
                          <FormLabel>Product Name</FormLabel>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                          <TextField
                            label="Product Name"
                            id="outlined-basic"
                            variant="outlined"
                            name="productName"
                            required
                            value={name}
                            className="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.productName}
                            helperText={errors.productName}
                            inputProps={{ maxLength: 30 }}
                            onKeyDown={(e) => {
                              handleKeyDown(e)
                              const allowedKeys = [
                                "Backspace",
                                "ArrowLeft",
                                "ArrowRight",
                                "Delete",
                                "Tab",
                                " "
                              ];
                              const allowedCharPattern = /^[0-9A-Za-z._-]$/;
        
                              // Check if the pressed key is not allowed
                              if (
                                !allowedKeys.includes(e.key) &&
                                !allowedCharPattern.test(e.key)
                              ) {
                                e.preventDefault(); // Prevent the default action of the disallowed key
                              }
                            }}
                          />
                        </div>
                      </div>
                    </Form.Group>

                    <Form.Group>
                      <div className="up-main-image" style={{ width: "100%" }}>
                        <div>
                          <FormLabel>
                            Product Main Image
                            {showAddImage === 1 ? (
                              <span>(choose one Image)</span>
                            ) : (
                              <></>
                            )}
                          </FormLabel>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                          {showAddImage === 1 ? (
                            <>
                              <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}
                                style={{ marginTop: "10px" }}
                                disabled={
                                  image !== null
                                 }
                              >
                                Product Image
                                <VisuallyHiddenInput
                                  type="file"
                                  accept="image/*"
                                  onChange={handleImageChange}
                                />
                              </Button>
                              <div>
                                {showImage === null ? (
                                  <></>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-evenly",
                                      alignItems: "center",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <img
                                      src={showImage}
                                      alt=""
                                     width="100px"
                                      height="100px"
                                    />
                                    <Button
                                      color="error"
                                      variant="contained"
                                      onClick={() => {
                                        setShowImage(null);
                                        setImage(null);
                                      }}
                                   
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </>
                          ) : (
                            <div className="image-delete-button">
                              <img
                                src={image}
                                alt=" "
                                width="100px"
                                height="100px"
                              />
                              <Button
                                variant="contained"
                                color="error"
                                onClick={() => {
                                  setShowAddImage(1);
                                  setImage(null);
                                }}
                                style={{
                                  marginTop: "27px",
                                  marginLeft: "15px",
                                  width: "20px",
                                  height: "40px",
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          )}
                          {errors.image && (
                            <div
                              style={{
                                color: "red",
                                marginLeft: "20px",
                                fontSize: "13px",
                              }}
                            >
                              {errors.image}
                            </div>
                          )}
                        </div>
                      </div>
                    </Form.Group>

                    <Form.Group>
                      <div className="up-description">
                        <div>
                          <FormLabel>Description</FormLabel>
                        </div>
                        <TextField
                          id="outlined-multiline-static"
                          label="Description"
                          name="description"
                          required
                          multiline
                          rows={4}
                          variant="outlined"
                          value={description}
                          onKeyDown={handleKeyDown}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.description}
                          helperText={errors.description}
                          style={{
                            marginTop: "10px",
                            width: "90%",
                          }}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group>
                      <div className="up-add-features">
                        <FormLabel>Salient Features</FormLabel>
                        <Button
                          variant="contained"
                          onClick={handleAddInput}
                          color="success"
                          style={{ height: "30px" }}
                          disabled={ !!errors.keyFeatures || inputField.some(field => field.value === "")}
                        >
                          Add
                        </Button>
                      </div>

                      {errors.features && (
                        <div
                          style={{
                            color: "red",
                            marginLeft: "20px",
                            fontSize: "13px",
                          }}
                        >
                          {errors.features}
                        </div>
                      )}
                      <div className="up-features">
                        {features.map((value, index) => {
                          return (
                            <div key={index + 1}>
                              <TextField
                                id="outlined-multiline-static"
                                label="Features"
                                name="features"
                                required
                                onKeyDown={handleKeyDown}
                                multiline
                                className="texts"
                                variant="outlined"
                                value={value}
                                onChange={(e) => handleFeatureChange(e, index)}
                              />
                              <Button
                                variant="contained"
                                color="error"
                                style={{
                                  marginTop: "27px",
                                  marginLeft: "15px",
                                  width: "20px",
                                }}
                                onClick={() => handleDelete(index)}
                              >
                                Delete
                              </Button>
                            </div>
                          );
                        })}
                      </div>
                      <div className="up-feature-dynamic">
                        {inputField.map((value, index) => {
                          return (
                            <div key={value.id}>
                              <TextField
                                className="texts"
                                multiline
                                id="outlined-multiline-static"
                                label="Features"
                                onKeyDown={handleKeyDown}
                                name="keyfeatures"
                                variant="outlined"
                                onChange={(e) => {
                                  handleDescriptionChange(e, value.id);
                                }}
                              />
                              <Button
                                variant="contained"
                                color="error"
                                style={{
                                  marginTop: "20px",
                                  marginLeft: "15px",
                                  width: "20px",
                                }}
                                onClick={() => {
                                  deleteInputField(value.id);
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          );
                        })}
                        {errors.keyFeatures && (
                          <div
                            style={{
                              color: "red",
                              marginLeft: "20px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.keyFeatures}
                          </div>
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group>
                      <div className="up-sub-image">
                        <div>
                          <FormLabel>
                            product sub Image{" "}
                            {showImageButton === 1 ? (
                              <span>(choose Multiple Image)</span>
                            ) : (
                              <></>
                            )}
                          </FormLabel>
                        </div>
                        {showImageButton === 1 ? (
                          <>
                            <Button
                              component="label"
                              role={undefined}
                              variant="contained"
                              tabIndex={-1}
                              startIcon={<CloudUploadIcon />}
                              style={{ marginTop: "15px" }}
                              disabled={
                               arrayImage.length !==0
                              }
                            >
                              Product Image
                              <VisuallyHiddenInput
                                type="file"
                                onChange={imageChange}
                                accept="image/*"
                                multiple
                              />
                            </Button>
                            <div>
                              {arrayImage.length === 0 ? (
                                <></>
                              ) : (
                                <>
                                  <div className="images-up">
                                    {arrayImage.map((value, index) => {
                                      return (
                                        <div key={index}>
                                          <img
                                            src={value}
                                            alt=""
                                             width="100px"
                                      height="100px"
                                          />
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <Button
                                    color="error"
                                    variant="contained"
                                    onClick={() => {
                                      setArrayImage([]);
                                      setImages([]);
                                    }}
                                    style={{
                                      marginTop:"10px"
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </>
                              )}
                            </div>
                          </>
                        ) : (
                          <div>
                            <div className="images-up">
                              {images.map((value, index) => {
                                return (
                                  <span key={index}>
                                    <img
                                      src={value}
                                      alt=""
                                      width="100px"
                                      height="100px"
                                    />
                                  </span>
                                );
                              })}
                            </div>
                            <Button
                              variant="contained"
                              color="error"
                              style={{
                                marginTop: "20px",
                                marginLeft: "15px",
                                width: "20px",
                              }}
                              onClick={() => {
                                setShowImageButton(1);
                                setImages([]);
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        )}
                        {errors.images && (
                          <div
                            style={{
                              color: "red",
                              marginLeft: "20px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.images}
                          </div>
                        )}
                      </div>
                    </Form.Group>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="contained"
                  onClick={updateProduct}
                  color="success"
                >
                  Save changes
                </Button>
              </Modal.Footer>
              {loading && <Loader />}
            </Modal>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ManageProduct;
